import {useAxiosParams} from "../common/useAxiosParams";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import {UpdateCaseDateRequest} from "../../models/requests/UpdateCaseDateRequest";
import {Section2CorrectionRequest} from "../../models/requests/Section2CorrectionRequest";
import {Section2CompleteRequest} from "../../models/requests/Section2CompleteRequest";
import {SupplementBCompleteRequest} from "../../models/requests/SupplementBCompleteRequest";
import {CaseResponse} from "../../models/responses/Cases/CaseResponse";
import {FirstDayCheckCompleteRequest} from "../../models/requests/FirstDayCheckCompleteRequest";
import { UpdateCaseHiringManagerRequest } from "../../models/requests/UpdateCaseHiringManagerRequest";
import {PurgeRequest} from "../../models/requests/PurgeCaseRequest";
import {useBrand} from "../Brand/useBrand";
import {UpdateConsumerReferenceRequest} from "../../models/requests/UpdateConsumerReferenceRequest";
import { PhotoMatch } from "../../models/requests/UpdateEverifyRequest";

type RenameRehireProps = {
    isEmployer: boolean,
    rehireDate?: string
}

type ReactivateProps = {
    startDate: string
}

export const useUpdateCaseHiringManager = (caseUid: string) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async (updateCaseHiringManagerRequest: UpdateCaseHiringManagerRequest) => {
            const response = await axios.patch(basePath + `/${caseUid}/hiringManager`, updateCaseHiringManagerRequest, config);
            return response.data;
        },
        onSuccess: () => {
            cache.invalidateQueries(["cases-details", caseUid])
            .then(() => (
                setTimeout(()=>{
                    window.location.reload()
                },1000)
            ));                
        }
    });
}

export const useUpdateCaseStartDate = (caseUid: string) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async (updateCaseDateRequest: UpdateCaseDateRequest) => {
            const response = await axios.patch(basePath + `/${caseUid}/startDate`, updateCaseDateRequest, config);
            return response.data;
        },
        onSuccess: () => {
            cache.invalidateQueries(["cases-details", caseUid])
            .then(() => (
                setTimeout(()=>{
                    window.location.reload()
                },1000)
            ));                
        }
    });
}

export const useUpdateCaseEndDate = (caseUid: string) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async (updateCaseDateRequest: UpdateCaseDateRequest) => {
            const response = await axios.patch(basePath + `/${caseUid}/endDate`, updateCaseDateRequest, config);
            return response.data;
        },
        onSuccess: () => {
            cache.invalidateQueries(["cases-details", caseUid])
            .then(() => (
                setTimeout(()=>{
                    window.location.reload()
                },1000)
            ));                
        }
    });
}

type useUpdateCaseConsumerReferenceProps = {
    caseUid: string;
    onSuccess: ()=>void;
    onError: ()=>void;
}

export const useUpdateCaseConsumerReference = ({caseUid, onSuccess, onError} : useUpdateCaseConsumerReferenceProps) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async (updateConsumerReferenceRequest: UpdateConsumerReferenceRequest) => {
            const response = await axios.patch(basePath + `/${caseUid}/consumerReference`, updateConsumerReferenceRequest, config);
            return response.data;
        },
        onSuccess: () => {
            onSuccess();
            cache.invalidateQueries(["cases-details", caseUid])
                .then(() => (
                    setTimeout(()=>{
                        window.location.reload()
                    },1000)
                ));
        },
        onError: (error) => {
            console.error(error);
            onError();
        }
    });
}

export const useCorrectSectionTwo = (caseUid: string) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async (correctionRequest: Section2CorrectionRequest) => {
            const response = await axios.patch(basePath + `/${caseUid}/section_2`, correctionRequest, config);
            return response.data;
        },
        onSuccess: data => {
            cache.setQueryData<CaseResponse>(["cases-details", caseUid], (emsCase) => ({
                ...data,
                specifications: emsCase?.specifications
            }));
        }
    });
}

export const useInviteSection3 = (caseUid: string) => { // FOR DEMO/TESTING PURPOSE: manually move a case forward to Supplement B Invited
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            const response = await axios.post(basePath + `/${caseUid}/events`, {
                "caseEventType": "REINVITED",
                "payload": null
            }, config);
            return response.data;
        },
        onSuccess: () => {
            cache.invalidateQueries(["cases-details", caseUid])
                .then(() => window.location.reload());
        }
    });
}

export const useRehire = (caseUid: string) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async ({isEmployer, rehireDate}: RenameRehireProps) => {
            const response = await axios.post(basePath + `/${caseUid}/events`, {
                "caseEventType": "REHIRE",
                "payload": {
                    "reviewReasons": null,
                    "isEmployer": isEmployer,
                    "rehireDate": rehireDate
                }
            }, config);
            return response.data;
        },
        onSuccess: () => {
            cache.invalidateQueries(["cases-details", caseUid])
                .then(() => window.location.reload());
        }
    });
}

export const useReactivate = (caseUid: string) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async ({startDate}: ReactivateProps) => {
            const response = await axios.post(basePath + '/reactivate', {
                "caseId": caseUid,
                "startDate": startDate
            }, config);
            return response.data;
        },
        onSuccess: () => {
            cache.invalidateQueries(["cases-details", caseUid])
                .then(() => (
                    setTimeout(()=>{
                        window.location.reload()
                    },1000)
                ));
        }
    });
}


export const useReinvite = (caseUid: string) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async (reviewReason: string) => {
            console.log(reviewReason)
            const response = await axios.post(basePath + `/${caseUid}/events`, {
                "caseEventType": "REINVITED",
                "payload": {
                    "reviewReasons": [reviewReason]
                }
            }, config);
            return response.data;
        },
        onSuccess: () => {
            cache.invalidateQueries(["cases-details", caseUid])
                .then(() => window.location.reload());
        }
    });
}

export const useReject = (caseUid: string, onSuccess?: (callback: () => void) => void) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async (rejectReason: string) => {
            console.log(rejectReason)
            const response = await axios.post(basePath + `/${caseUid}/events`, {
                "caseEventType": "MANUAL_REJECT",
                "payload": {
                    "reviewReasons": [rejectReason]
                }
            }, config);
            return response.data;
        },
        onSuccess: () => {
            if (onSuccess) {
                onSuccess(() => {
                    cache.invalidateQueries(["cases-details", caseUid])
                        .then(() => window.location.href = window.location.origin + "/cases/" + caseUid);
                });
            } else {
                cache.invalidateQueries(["cases-details", caseUid])
                    .then(() => window.location.href = window.location.origin + "/cases/" + caseUid);
            }
        }
    });
}

export const useCancel = (caseUid: string, onSuccess?: (callback: () => void) => void) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            const response = await axios.post(basePath + `/${caseUid}/events`, {
                "caseEventType": "APPLICATION_CANCELLED"
            }, config);
            return response.data;
        },
        onSuccess: () => {
            if (onSuccess) {
                onSuccess(() => {
                    cache.invalidateQueries(["cases-details", caseUid]);
                });
            } else {
                cache.invalidateQueries(["cases-details", caseUid]);
            }

        }
    });
}

export const useNameChange = (caseUid: string) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async ({isEmployer}: RenameRehireProps) => {
            const response = await axios.post(basePath + `/${caseUid}/events`, {
                "caseEventType": "RENAME",
                "payload": {
                    "reviewReasons": null,
                    "isEmployer": isEmployer,
                    "rehireDate": null
                }
            }, config);
            return response.data;
        },
        onSuccess: () => {
            cache.invalidateQueries(["cases-details", caseUid])
                .then(() => window.location.reload());
        }
    });
}

export const useRejectCaseDocuments = (caseUid: string) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            const response = await axios.post(basePath + `/${caseUid}/events`, {
                "caseEventType": "ARCHIVED",
                "payload": null
            }, config);
            return response.data;
        },
        onSuccess: () => {
            cache.invalidateQueries(["cases-details", caseUid])
                .then(() => window.location.href = window.location.origin + "/cases/" + caseUid);
        }
    });
}

export const useCompleteFirstDayCheck = (caseUid: string, onSuccess: () => void, onError: () => void) => {
    const {basePath, config} = useAxiosParams()

    return useMutation({
        mutationFn: async (completeFirstDayCheckRequest: FirstDayCheckCompleteRequest) => {
            const response = await axios.post(basePath + `/${caseUid}/submitData`, completeFirstDayCheckRequest, config);
            return response.data;
        },
        onSuccess: () => {
            onSuccess();
        },
        onError: () => {
            onError();
        }
    });
}

export const useCompleteSectionTwo = (caseUid: string, onSuccess: () => void, onError: () => void) => {
    const {basePath, config} = useAxiosParams()

    return useMutation({
        mutationFn: async (completeSection2Request: Section2CompleteRequest) => {
            const response = await axios.post(basePath + `/${caseUid}/submitData`, completeSection2Request, config);
            return response.data;
        },
        onSuccess: () => {
            onSuccess();
        },
        onError: () => {
            onError();
        }
    });
}

export const useExitCaseMeetingWithoutReview = (caseUid: string) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async (reason: string) => {
            const response = await axios.post(basePath + `/${caseUid}/events`, {
                "caseEventType": "MANUAL_REJECT",
                "payload": {"reviewReasons": [reason]}
            }, config);
            return response.data;
        },
        onSuccess: () => {
            cache.invalidateQueries(["cases-details", caseUid])
                .then(() => window.location.href = window.location.origin + "/cases/" + caseUid);
        }
    });
}

export const useReferCase = (caseUid: string) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async (reason: string) => {
            const response = await axios.post(basePath + `/${caseUid}/events`, {
                "caseEventType": "REFERRED",
                "payload": {"reviewReasons": [reason]}
            }, config);
            return response.data;
        },
        onSuccess: () => {
            cache.invalidateQueries(["cases-details", caseUid])
                .then(() => window.location.href = window.location.origin + "/cases/" + caseUid);
        }
    });
}

export const useSubmitCaseEvent = (caseUid: string, onSuccess?: () => void, onError?: () => void, onChangePage?: () => void) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async (caseEventType: string) => {
            const response = await axios.post(basePath + `/${caseUid}/events`, {
                "caseEventType": caseEventType,
                "payload": null
            }, config);
            return response.data;
        },
        onSuccess: () => {
            onSuccess && onSuccess();
            cache.invalidateQueries(["cases-details", caseUid])
                .then(() => {
                    if (onChangePage) {
                        onChangePage();
                    } else {
                        window.location.href = window.location.origin + "/cases/" + caseUid
                    }
                });
        },
        onError: () => {
            onError && onError();
        }
    });
}

export const useCompleteSupplementB = (caseUid: string, onSuccess: () => void, onError: () => void) => {
    const {basePath, config} = useAxiosParams()

    return useMutation({
        mutationFn: async (completeRequest: SupplementBCompleteRequest) => {
            const response = await axios.post(basePath + `/${caseUid}/submitData`, completeRequest, config);
            return response.data;
        },
        onSuccess: () => {
            onSuccess();
        },
        onError: () => {
            onError();
        }
    })
}

export const useUploadShareCodePdf = (caseUid: string, onSuccess?: () => void, onError?: () => void, onChangePage?: () => void) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async (payload: File) => {
            const req = new FormData();
            req.append('file', payload);
            const response = await axios.post(basePath + `/${caseUid}/shareCode/file`, req, config)
            return response.data;
        },
        onSuccess: () => {
            onSuccess && onSuccess();
            cache.invalidateQueries(["cases-details", caseUid])
                .then(() => {
                    if (onChangePage) {
                        onChangePage();
                    } else {
                        window.location.href = window.location.origin + "/cases/" + caseUid
                    }
                });
        },
        onError: () => {
            onError && onError();
        }
    });
}

export const useDeleteShareCodePdf = (caseUid: string, onSuccess?: () => void, onError?: () => void) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            const response = await axios.delete(basePath + `/${caseUid}/shareCode/file`, config)
            return response.data;
        },
        onSuccess: () => {
            onSuccess && onSuccess();
            cache.setQueryData<CaseResponse>(["cases-details", caseUid],
                (emsCase) => {
                    return emsCase && {
                        ...emsCase,
                        additionalData: {
                            ...emsCase.additionalData,
                            shareCodePdf: undefined,
                        }
                    }
                }
            );
        },
        onError: () => {
            onError && onError();
        }
    });
}

export const useResendNotifications = (caseUid: string, onSuccess?: () => void, onError?: () => void) => {
    const {basePath, config} = useAxiosParams()

    return useMutation({
        mutationFn: async () => {
            await axios.post(basePath + `/${caseUid}/notifications`, {}, config);
        },
        onSuccess: () => {
            onSuccess && onSuccess();
        },
        onError: () => {
            onError && onError();
        }
    });
}

export const usePurge = (onSuccess: () => void, onError: () => void) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();
    const {data: brand} = useBrand();

    return useMutation({
        mutationFn: async (purgeRequest: PurgeRequest) => {
           await axios.post(basePath + `/purge`, purgeRequest, config);
           await cache.invalidateQueries(["cases", brand?.id]);
        },
        onSuccess: () => {
            onSuccess();
        },
        onError: () => {
            onError();
        }
    })
}

export const useUpdateEverifyStatus = (caseUid: string, onSuccess?: () => void, onError?: () => void) => {
    const {basePath, config} = useAxiosParams()

    return useMutation({
        mutationFn: async () => {
            await axios.post(basePath + `/${caseUid}/everify-update`, {}, config);
        },
        onSuccess: () => {
            onSuccess && onSuccess();
        },
        onError: () => {
            onError && onError();
        }
    });
}

export const useResubmitEverify = (caseUid: string, onSuccess?: () => void, onError?: () => void) => {
    const {basePath, config} = useAxiosParams()
    const cache = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            await axios.post(basePath + `/${caseUid}/everify-resubmit`, {}, config);
        },
        onSuccess: () => {
            cache.invalidateQueries(["cases-details", caseUid])
                .then(() => {
                    onSuccess && onSuccess();
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000)
                });
        },
        onError: () => {
            onError && onError();
        }
    });
}


export const useEverifyRetrievePhoto = (caseUid: string, onSuccess?: () => void, onError?: () => void) => {
    const {basePath, config} = useAxiosParams()

    return useMutation({
        mutationFn: async () => {
            await axios.post(basePath + `/${caseUid}/everify/retrieve-photo`, {}, config);
        },
        onSuccess: () => {
            onSuccess && onSuccess();
        },
        onError: () => {
            onError && onError();
        }
    });
}

export const useEverifyPhotoMatch = (caseUid: string, onSuccess?: () => void, onError?: () => void) => {
    const {basePath, config} = useAxiosParams()

    return useMutation({
        mutationFn: async (photoMatch: PhotoMatch) => {
            await axios.post(basePath + `/${caseUid}/everify/photo-match`, {
                photoMatch: photoMatch
            }, config);
        },
        onSuccess: () => {
            onSuccess && onSuccess();
        },
        onError: () => {
            onError && onError();
        }
    });
}