import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {CaseResponse, getRawDueDate, getStatus} from "../models/responses/Cases/CaseResponse";
import {isBetweenDate} from "../utils/DateUtils/dateUtils";
import JourneyContext from "./JourneyContext";
import {JourneyInfo} from "../models/responses/JourneyInfo";

export type I9FilterDateType = "startDate" | "dueDate" | "createdAt";
export type RtwFilterDateType = "createdAt" | "appointmentDate";
export type FilterDateType = I9FilterDateType | RtwFilterDateType;

// eslint-disable-next-line
export type EventHandler = (event : any) => void;

type CaseListFilterInterface = {
    filterStatuses : string[];
    filterText: string;
    filterDate: string;
    filterDateEnd: string;
    dateType: FilterDateType;
    setFilterStatuses : (statuses: string[]) => void;
    setFilterText: (text: string) => void;
    setFilterDate: (date: string) => void;
    setFilterDateEnd: (date: string) => void;
    setDateType: (dateType: FilterDateType) => void;
    handleFilterStatusSelectChange: EventHandler;
    handleRemoveFilterStatus: (status: string) => void;
    filterCases: (emsCases : CaseResponse[], currentJourney: JourneyInfo | undefined) => CaseResponse[];
}

export const CaseListFilterContext = createContext<CaseListFilterInterface>({
    filterStatuses: [],
    filterText: "",
    filterDate: "",
    filterDateEnd: "",
    dateType: "dueDate",
    setFilterStatuses: ()=>null,
    setFilterText: ()=>null,
    setFilterDate: ()=>null,
    setFilterDateEnd: ()=>null,
    setDateType: ()=>null,
    handleFilterStatusSelectChange: ()=>null,
    handleRemoveFilterStatus: ()=>null,
    filterCases: ()=>[],
})

export const CaseListFilterProvider = ({
                                           children,
                                       }: {
    children: React.ReactNode;
}) => {
    const {currentJourney} = useContext(JourneyContext);
    const [filterStatuses, setFilterStatuses] = useState<string[]>(sessionStorage.getItem("filterStatuses")?.split(",").filter(s => s.length) as string[] || []);
    const [filterText, setFilterText] = useState<string>(sessionStorage.getItem("filterText") || "");
    const [filterDate, setFilterDate] = useState<string>(sessionStorage.getItem("filterDate") || "");
    const [filterDateEnd, setFilterDateEnd] = useState<string>(sessionStorage.getItem("filterDateEnd") || "");
    const [dateType, setDateType] = useState<FilterDateType>(sessionStorage.getItem("dateType") as FilterDateType || "startDate");

    useEffect(()=>{
        sessionStorage.setItem("filterStatuses", filterStatuses.join(","))
    },[filterStatuses])

    useEffect(()=>{
        sessionStorage.setItem("filterText", filterText)
    },[filterText])

    useEffect(()=>{
        sessionStorage.setItem("filterDate", filterDate)
    },[filterDate])

    useEffect(()=>{
        sessionStorage.setItem("filterDateEnd", filterDateEnd)
    },[filterDateEnd])

    useEffect(()=>{
        sessionStorage.setItem("dateType", dateType)
    },[dateType])

    useEffect(()=>{
        setFilterStatuses([]);
    }, [currentJourney])

    const handleFilterStatusSelectChange : EventHandler = (event) => {
        const {
            target: { value },
        } = event;
        setFilterStatuses(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleRemoveFilterStatus = useCallback((status : string) => {
        setFilterStatuses(filterStatuses.filter(item => item !== status));
    }, [filterStatuses, setFilterStatuses])

    const filterCases = (emsCases : CaseResponse[], currentJourney: JourneyInfo | undefined) => {
        let filteredCases =  emsCases.filter(emsCase => emsCase.journeyId === currentJourney?.uid);
        if (filterStatuses.length > 0) {
            const statusNames = filterStatuses.flatMap(filter => currentJourney?.uiConfig.filter[filter].statuses);
            filteredCases = filteredCases.filter(
                emsCase => statusNames?.includes(getStatus(emsCase)) || ((statusNames?.includes("E_VERIFY") ||
                    filterStatuses.includes("FINAL_NONCONFIRMATION") ||
                    filterStatuses.includes("EMPLOYMENT_AUTHORIZED") ||
                    filterStatuses.includes("TENTATIVE_NONCONFIRMATION") ||
                    filterStatuses.includes("CASE_CREATION_FAILED")
                ) && (emsCase.additionalData["everify_case_status"] || emsCase.additionalData["everify_case_creation_failed"]))
            );

            if (
              filterStatuses.includes("FINAL_NONCONFIRMATION") ||
              filterStatuses.includes("EMPLOYMENT_AUTHORIZED") ||
              filterStatuses.includes("TENTATIVE_NONCONFIRMATION") ||
              filterStatuses.includes("CASE_CREATION_FAILED")
            ) {
              filteredCases = filteredCases.filter(
                (emsCase) => (filterStatuses.includes("EMPLOYMENT_AUTHORIZED") && emsCase.additionalData.everify_case_eligibility_statement == "EMPLOYMENT_AUTHORIZED")
                    || (filterStatuses.includes("FINAL_NONCONFIRMATION") && ["CLOSED", "FINAL_NONCONFIRMATION"].includes(emsCase.additionalData.everify_case_status || "") && emsCase.additionalData.everify_case_eligibility_statement !== "EMPLOYMENT_AUTHORIZED" && emsCase.additionalData.everify_case_creation_failed !== true)
                    || (filterStatuses.includes("TENTATIVE_NONCONFIRMATION") && !["CLOSED", "FINAL_NONCONFIRMATION"].includes(emsCase.additionalData.everify_case_status || "") && emsCase.additionalData.everify_case_eligibility_statement !== "EMPLOYMENT_AUTHORIZED" && emsCase.additionalData.everify_case_creation_failed !== true)
                    || (filterStatuses.includes("CASE_CREATION_FAILED") && emsCase.additionalData.everify_case_creation_failed == true)
              );
            }
        }
        if (filterText.length > 0) {
            const reg = new RegExp(
                filterText.split("").join("\\w*").replace(/\W/, ""),
                "i"
            );
            filteredCases = filteredCases.filter(
                emsCase => reg.test(emsCase.givenName) || reg.test(emsCase.familyName) || reg.test(emsCase.consumerReference) || reg.test(emsCase.emailAddress)
             )
        }
        if (filterDate.length > 0 || filterDateEnd.length > 0) {
            if (dateType === "startDate") {
                filteredCases = filteredCases.filter(
                    emsCase => isBetweenDate(emsCase.startDate, filterDate, filterDateEnd)
                )
            } else if (dateType === "createdAt") {
                filteredCases = filteredCases.filter(
                    emsCase => isBetweenDate(emsCase.createdAt, filterDate, filterDateEnd)
                )
            } else if (dateType === "dueDate") {
                filteredCases = filteredCases.filter(
                    emsCase => isBetweenDate(getRawDueDate(emsCase), filterDate, filterDateEnd)
                )
            } else if (dateType === "appointmentDate") {
                filteredCases = filteredCases.filter(
                    emsCase => isBetweenDate(emsCase?.additionalData?.meeting?.startTime as string, filterDate, filterDateEnd)
                )
            }
        }
        return filteredCases;
    }

    return <CaseListFilterContext.Provider value={{
        filterStatuses,
        filterText,
        filterDate,
        filterDateEnd,
        dateType,
        setFilterStatuses,
        setFilterText,
        setFilterDate,
        setFilterDateEnd,
        setDateType,
        handleFilterStatusSelectChange,
        handleRemoveFilterStatus,
        filterCases
    }}> { children } </CaseListFilterContext.Provider>
}
