import React, {FC, useCallback, useMemo} from "react";
import {GenericJsonObject} from "../../../models/responses/Cases/CaseResponse";
import {AppBar, Grid, Typography} from "@mui/material";
import {DetailsBlock, DetailsFields, EditableFieldProps, StatusLabel} from "../common";
import {WORK_AUTH_STATUS_DICTIONARY} from "../../../constants/dictionaries"
import {CaseTabContext, TabMode} from "../../../pages/CaseDetails/useCaseControl.hook";
import {PrimaryButton, SecondaryButton} from "../../../pages/CreateCase/CreateCaseForm/styles";
import {ConfirmationModelContainer, useConfirmationModal} from "../../ConfirmationModal";
import {useSection2CorrectionForm} from "./useSection2CorrectionForm";
import {useCorrectSectionTwo} from "../../../hooks/Cases/useMutateCase";
import I9Form from "../I9Form";
import {getDisplayDocumentTitleAboveImage} from "../../../utils/CaseUtils/documentsDisplayUtil";
import {getDisplayDocumentListType} from "../../../utils/CaseUtils/documentListType";
import DocumentImage from "../../DocumentImage";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const SectionTwoPanel: FC<{
    data: GenericJsonObject, prevData?: GenericJsonObject,
    context?: CaseTabContext,
    i9PdfFile?: Blob | undefined,
    handleRefresh?: () => void,
    images?: GenericJsonObject,
    handleCompare?: (e?: string) => void
}> =
    ({data, prevData, context, i9PdfFile, handleRefresh, images, handleCompare}) => {

        const caseId = context?.caseId ? context.caseId : "";
        const {mutate, isLoading, isError} = useCorrectSectionTwo(caseId);

        const isCorrectionMode = useMemo(() => (context?.mode === TabMode.CORRECTION), [context]);
        const modal = useConfirmationModal();

        // eslint-disable-next-line
        const employeeData: any = data.employee;
        // eslint-disable-next-line
        const repData: any = data.employer_or_authorized_representatives;
        // eslint-disable-next-line
        const documents: { [key: string]: string }[] = repData.documents;
        // eslint-disable-next-line
        const prevRepData: any = prevData?.employer_or_authorized_representatives;
        // eslint-disable-next-line
        const prevDocuments: { [key: string]: string }[] = prevRepData?.documents;
        // eslint-disable-next-line
        const employerData: any = data.employer || {
            business_or_org_address: "",
            business_or_org_name: "",
            city_or_town: "",
            state: "",
            title: "",
            zip_code: "",
        };

        // eslint-disable-next-line
        const standaloneTransactionsSectionTwo: any = (images?.standaloneTransactions as any)?.Section_2 || [];

        // eslint-disable-next-line
        const sectionDocumentImages: any = [];
        standaloneTransactionsSectionTwo &&
        // eslint-disable-next-line
        standaloneTransactionsSectionTwo.forEach((transaction: any) => {
            Object.keys(transaction.documents).sort().forEach((key) =>
                sectionDocumentImages.push({
                    title: key,
                    image: transaction.documents[key].image,
                })
            );
        });

        const {data: correctionData, resetFormData, handleChange, handleDocumentChange} = useSection2CorrectionForm({
            title: repData.title,
            last_name: repData.last_name,
            first_name: repData.first_name,
            documents: documents,
        });

        const requestCorrectionHandler = useCallback(() => {
            mutate(correctionData)
        }, [correctionData])

        const onRequestCorrection = () => {
            modal.openConfirmationModal({
                message: "Are you sure you want to make edits to Section 2 of this file?",
                content: "It's the legal responsibility of the employers to make corresponding changes to the corresponding E-Verify case.",
                confirmButtonLabel: "Confirm",
                onConfirm: () => {
                    requestCorrectionHandler()
                },
                onCancel: () => undefined
            })
        }

        const onCancelCorrection = () => {
            resetFormData({
                title: repData.title,
                last_name: repData.last_name,
                first_name: repData.first_name,
                documents: documents,
            });
            handleRefresh && handleRefresh();
        }

        const handleCancelCorrectionRequest = () => {
            modal.openConfirmationModal({
                message: "Are you sure you want to leave? You will lose your unsaved progress.",
                confirmButtonLabel: "Confirm",
                onConfirm: onCancelCorrection,
                onCancel: () => undefined
            })
        }

        const getEditableProps = (key: string): EditableFieldProps | undefined => {
            if (!isCorrectionMode) {
                return undefined;
            }
            return {
                // eslint-disable-next-line
                // @ts-ignore
                value: correctionData[key] as string,
                type: "text",
                handleChange: handleChange(key),
            }
        }

        const getEditableDocumentProps = (index: number, key: string, type: "text" | "date"): EditableFieldProps | undefined => {
            if (!isCorrectionMode) {
                return undefined;
            }
            return {
                // eslint-disable-next-line
                // @ts-ignore
                value: correctionData.documents[index][key] as string,
                type: type,
                handleChange: handleDocumentChange(index, key),
            }
        }

        const decorate = (prevValue: string | undefined, currentValue: string) => {
            return prevValue && prevValue !== currentValue ?
                `<span style="text-decoration: line-through;">${prevValue}</span>&nbsp&nbsp${currentValue}`
                : `${currentValue}`;
        }

        const handleCompareButtonClick = () => {
            handleCompare && handleCompare(context?.compareSection)
        }

        try {
            return <Grid container p={2} alignItems={"flex-start"}>
                <Grid container flexDirection={"row"} item xs={12}>
                    <DetailsBlock xs={12} borderBottom>
                        <Grid container item xs={12} style={{fontWeight: 600, marginBottom: 10,}}>
                            <Grid item xs={8} style={{fontWeight: 600}}>Candidate Details</Grid>
                            <Grid container item xs={4} alignItems={"center"} justifyContent={"flex-end"}>
                                <StatusLabel
                                    backgroundColor={"rgba(133, 232, 139, 0.3)"}
                                    borderColor={"#85E88B"}
                                    label={"Completed"}
                                />
                            </Grid>
                        </Grid>

                        <DetailsFields
                            keyPrefix={"candidate-"}
                            items={[
                                {
                                    label: "Last name (Family name)",
                                    value: `${employeeData.last_name}`
                                },
                                {
                                    label: "First name (Given name)",
                                    value: `${employeeData.first_name}`
                                },
                                {
                                    label: "Middle Initial",
                                    value: `${employeeData.middle_initial}`,
                                    xs: 3
                                },
                                {
                                    label: "Citizen immigration status",
                                    value: WORK_AUTH_STATUS_DICTIONARY[employeeData.work_auth.status] || `Unknown status: ${employeeData.work_auth.status}`
                                }
                            ]}
                        />
                    </DetailsBlock>


                    {
                        documents.map((document, key) => <DetailsBlock xs={12}
                                                                       borderBottom={key === documents.length - 1}
                                                                       key={`document-details-${key}`}>
                            {
                                (key === 0) &&
                                <Grid container item xs={12} style={{fontWeight: 600, marginBottom: 20,}}>
                                    <Grid item xs={8} style={{fontWeight: 600}}>Document Details</Grid>
                                </Grid>
                            }
                            <Grid container item xs={12}>
                                <Grid item xs={8} style={{
                                    fontWeight: 600,
                                    fontSize: 15
                                }}>{getDisplayDocumentListType(document.list_type)}</Grid>
                            </Grid>
                            <DetailsFields
                                keyPrefix={`document-${key}-`}
                                items={[
                                    {
                                        label: "Document title",
                                        value: decorate(prevDocuments?.[key]?.document_title, document.document_title),
                                        field: prevData && `doc-${key}-title`,
                                        editableProps: getEditableDocumentProps(key, "document_title", "text"),
                                    },
                                    {
                                        label: "Issuing authority",
                                        value: decorate(prevDocuments?.[key]?.issuing_authority, document.issuing_authority),
                                        field: prevData && `doc-${key}-issuing_authority`,
                                        editableProps: getEditableDocumentProps(key, "issuing_authority", "text"),
                                    },
                                    {
                                        label: "Document number",
                                        value: decorate(prevDocuments?.[key]?.document_number, document.document_number),
                                        xs: 3,
                                        field: prevData && `doc-${key}-document_number`,
                                        editableProps: getEditableDocumentProps(key, "document_number", "text"),
                                    },
                                 {
                                        label: "Expiration date",
                                        value: decorate(
                                            (prevRepData && prevDocuments?.[key]?.date_of_expiry && !['', 'N/A'].includes(prevDocuments?.[key]?.date_of_expiry))
                                                ? dayjs.utc(prevDocuments?.[key]?.date_of_expiry).format('MM/DD/YYYY')
                                                : prevDocuments?.[key]?.date_of_expiry,
                                            (!['', 'N/A'].includes(document.date_of_expiry))
                                                ? dayjs.utc(`${document.date_of_expiry}`).format('MM/DD/YYYY')
                                                : document.date_of_expiry
                                        ),
                                        field: prevData && `doc-${key}-date_of_expiry`,
                                        editableProps: getEditableDocumentProps(key, "date_of_expiry", "date"),
                                    }
                                ]}
                            />
                        </DetailsBlock>)
                    }
                    <DetailsBlock xs={12} title={"Employer or Authorized Representative Details"} borderBottom>
                        <DetailsFields
                            keyPrefix={"rep-"}
                            items={[
                                {
                                    label: "Signature of Employer or Authorized Rep",
                                    value: `${repData.signature.image}`,
                                    image: repData.signature.imageQuery,
                                },
                                {
                                    label: "Date",
                                    value: repData.signature.date ? dayjs.utc(`${repData.signature.date}`).format('MM/DD/YYYY') : ''
                                },
                                {
                                    label: "Title of Employer or Authorized Rep",
                                    value: decorate(prevRepData?.title, repData.title),
                                    field: prevData && "rep_title",
                                    editableProps: getEditableProps("title"),
                                },
                                {
                                    label: "Last name",
                                    value: decorate(prevRepData?.last_name, repData.last_name),
                                    field: prevData && "rep_last_name",
                                    editableProps: getEditableProps("last_name"),
                                },
                                {
                                    label: "First name",
                                    value: decorate(prevRepData?.first_name, repData.first_name),
                                    field: prevData && "rep_first_name",
                                    editableProps: getEditableProps("first_name"),
                                },
                                {
                                    label: "Employers business or organization name",
                                    value: `${employerData.business_or_org_name}`
                                },
                                {
                                    label: "Employers business or organization address",
                                    value: `${employerData.business_or_org_address}`
                                },
                                {
                                    label: "City or Town",
                                    value: `${employerData.city_or_town}`
                                },
                                {
                                    label: "State",
                                    value: `${employerData.state}`,
                                    xs: 3
                                },
                                {
                                    label: "Zip code",
                                    value: `${employerData.zip_code}`,
                                    xs: 3
                                },
                            ]}
                        />
                    </DetailsBlock>
                    <DetailsBlock xs={12} borderBottom title="Documents" compareButton
                                  compareButtonClick={handleCompareButtonClick}>
                        <Grid container spacing={1}>
                            <Grid item container xs={5}>
                                {sectionDocumentImages.map((item: { title: string, image: string }, index: number) => (
                                    <DocumentImage key={index}
                                                   imageName={getDisplayDocumentTitleAboveImage(documents, item.title)}
                                                   imageScr={item.image}/>
                                ))}
                            </Grid>
                            <Grid item container xs={7}>
                                <I9Form i9PdfFile={i9PdfFile}/>
                            </Grid>
                        </Grid>
                    </DetailsBlock>
                </Grid>
                <ConfirmationModelContainer {...modal}/>
                <AppBar
                    position="fixed"
                    sx={{
                        borderTop: 1,
                        borderColor: "divider",
                        top: "auto",
                        bottom: 0,
                        background: "white",
                    }}
                    style={{display: isCorrectionMode ? "" : "none"}}
                >
                    <Grid container item xs={12} style={{flexGrow: 1, padding: 20}}>
                        <Grid item xs={8}></Grid>
                        <Grid
                            container
                            item
                            xs={4}
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                        >
                            <SecondaryButton variant="contained"
                                             onClick={handleCancelCorrectionRequest}>
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton
                                onClick={() => onRequestCorrection()}
                                disabled={isLoading || isError}
                            >
                                Submit
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </AppBar>
            </Grid>
        } catch (e) {
            console.error(e)
            return <Typography color={"error"} padding={1}> Unexpected data received for Section 2 </Typography>
        }
    }
