import {useState, useMemo, useContext} from "react";
import { GenericJsonObject } from "../../../models/responses/Cases/CaseResponse";
import {
    AlertTitle,
    AppBar, Button, Chip,
    Grid,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { FC } from "react";
import { ReactComponent as AccountCircleOutlinedIcon } from "../../../img/profile-icon.svg";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { DetailsBlock } from "../common";
import {formatDate, formatDateTime} from "../../../utils/DateUtils/dateUtils";
import {
  AlternativeProcedureBox,
  AuthenticatedAccountAlert,
  CaseProfileBox,
  CaseDetailsBox,
  EverifyDetailsBox,
  ExpirationDateBadge,
  StartEndDateField,
  SummaryBox,
  StatusSummaryTableRow,
  StatusSummaryTableCell,
  VerifyDocumentsButton, RequestOptOutFromSection2Box, RequestOptOutFromSection3Box, ReferredBox,
  PhotoMatchBox,
  FNCBox
} from "./styles";
import {
  CaseTabContext,
  StatusSummary,
} from "../../../pages/CaseDetails/useCaseControl.hook";
import dayjs from "dayjs";
import {
  useConfirmationModal,
  ConfirmationModelContainer,
} from "../../../components/ConfirmationModal";
import {
  useResubmitEverify,
  useUpdateCaseEndDate,
  useUpdateCaseHiringManager,
  useUpdateCaseStartDate,
  useUpdateEverifyStatus,
} from "../../../hooks/Cases/useMutateCase";
import CaseListStatusBadge from "../../../pages/Cases/CaseListStatusBadge";

import { Location } from "../../../models/responses/Location";
import AuthenticationContext from "../../../context/AuthenticationContext";
import JourneyContext from "../../../context/JourneyContext";
import ScheduleAppointmentModal from "../ScheduleAppointmentModal";
import {useOAuthStatus} from "../../../hooks/Meetings/useOAuthStatus";
import {Meeting} from "../../../models/responses/Cases/Meeting";
import {useApproveOptOut} from "../../../hooks/Cases/useApproveOptOut";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../../../pages/CreateCase/CreateCaseForm/styles";
import {styled} from "@mui/material/styles";
import {ColorUtils} from "../../../utils/ColorUtils";

// eslint-disable-next-line
export const BasicDetailsPanel: FC<{
  data: GenericJsonObject;
  context?: CaseTabContext;
  handleSubmitSuccess?: (caseId: string) => void;
}> = ({ data }) => {
  const [isScheduleAppointmentModalOpen, setScheduleAppointmentModalOpen] = useState(false);
  const [isRescheduleAppointmentModalOpen, setRescheduleAppointmentModalOpen] = useState(false);

  const [startDate, setStartDate] = useState<unknown>(
    data.startDate && dayjs(`${data.startDate}`)
  );
  const [endDate, setEndDate] = useState(
    data.endDate && dayjs(`${data.endDate}`)
  );

  const { mutate } = useUpdateEverifyStatus(data.uid as string);
  const handleEverifyUpdate = () => {
    mutate();
  };

  const {
    mutate: resubmitEverify,
    isLoading: isLoadingEverifyResubmit,
    isSuccess: isSuccessEverifyResubmit
  } = useResubmitEverify(data.uid as string,
      () => {
      toast.success("Case is re-submitted to E-verify");
    },
      () => {
      toast.error("Could not re-submit case to E-verify");
    });

  const isDisabledEverifyResubmit = isLoadingEverifyResubmit || isSuccessEverifyResubmit

  const handleEverifyResubmit = () => {
    resubmitEverify();
  };

  const navigate = useNavigate();
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [hiringManagerName, setHiringManagerName] = useState<string>(data.hiringManagerName as string);
  const [hiringManagerLastName, setHiringManagerLastName] = useState<string>(data.hiringManagerLastName as string);
  const [hiringManagerEmail, setHiringManagerEmail] = useState<string>(data.hiringManagerEmail as string);
  const [hiringManagerPhone, setHiringManagerPhone] = useState<string>(data.hiringManagerPhone as string);
  const [hiringManagerTitle, setHiringManagerTitle] = useState<string>(data.hiringManagerTitle as string);

  const { currentJourney } = useContext(JourneyContext);

  const {mutate: optOut, isLoading: optingOutInProcess} = useApproveOptOut({
      caseUid: data.uid as string,
      brandUid: currentJourney?.brandId,
      onSuccess: () => {
          navigate("/");
          toast.success("Successfully opted out");
      },
      onError: () => {
          toast.error("Could not opting out");
      }
  });

  const getLocationForId = (locationId: string | string[] | number | GenericJsonObject | GenericJsonObject[] | undefined | boolean):Location | null => {
    if(locationId === null || locationId === undefined || locationId === '') {
      return null;
    }
    return (currentJourney?.locations || []).find(location => location.id === locationId) || null;
  }

  const modal = useConfirmationModal();
  const { mutate: updateCaseStartDate } = useUpdateCaseStartDate(`${data.uid}`);
  const { mutate: updateCaseEndDate } = useUpdateCaseEndDate(`${data.uid}`);
  const { mutate: updateCaseHiringManager } = useUpdateCaseHiringManager(`${data.uid}`);

  const statusSummary: StatusSummary = data.statusSummary as StatusSummary;

  const { auditRole } = useContext(AuthenticationContext);

  // eslint-disable-next-line
  const formatToString = (date: any) => {
    return  new Date(date).toLocaleDateString("sv-SE") + "T00:00:00.000Z";
  }

  const handleHiringManagerChange = () => {
    updateCaseHiringManager({
      hiringManagerName: hiringManagerName,
      hiringManagerLastName: hiringManagerLastName,
      hiringManagerEmail: hiringManagerEmail,
      hiringManagerPhone: hiringManagerPhone,
      hiringManagerTitle: hiringManagerTitle
      }
    )
  }

  // eslint-disable-next-line
  const handleStartDateChange = (date: any) => {
    setStartDate(date);
    modal.openConfirmationModal({
      message: "Are you sure you want to update the candidate’s start date?",
      confirmButtonLabel: "Apply",
      onConfirm: () => {
        updateCaseStartDate({
          date: formatToString(date),
        });
      },
      onCancel: () => {
        setStartDate(data.startDate && dayjs(`${data.startDate}`));
      },
    });
  };

  // eslint-disable-next-line
  const handleEndDateChange = (date: any) => {
    setEndDate(date);
    modal.openConfirmationModal({
      message: "Are you sure you want to update the candidate’s end date? You won’t be able to request correction on Section 1 and Section 2 data after the case end date.",
      confirmButtonLabel: "Apply",
      onConfirm: () => {
        updateCaseEndDate({
          date: date && formatToString(date),
        });
      },
      onCancel: () => {
        setEndDate(data.endDate && dayjs(`${data.endDate}`));
      },
    });
  };

  const expiryMessage : string | null = useMemo(()=>{
    if (statusSummary.Section_1.status === "in_progress") {
      return `Section 1 due on ${formatDateTime(statusSummary.Section_1.due)}`;
    }
    if (statusSummary.Section_2.status === "in_progress" || statusSummary.Section_2.status.includes("OPT_OUT")) {
      return `Section 2 due on ${formatDateTime(statusSummary.Section_2.due)}`;
    }
    if (["SECTION_3", "SECTION_3_SUBMITTED"].includes(data.status as string)) {
      return `Supplement B due on ${formatDateTime(statusSummary.Section_3.due)}`;
    }
    return null;
  }, [statusSummary]);

  const  statusBadgeColor  =  currentJourney?.uiConfig.statusBadge[data.status as string]?.color;
  const StatusBadge = styled(Chip)(() => ({
        padding: "20px 10px",
        borderRadius: "80px",
        border: '2px solid ' + (statusBadgeColor? statusBadgeColor : '#81EAB2'),
        backgroundColor: statusBadgeColor? ColorUtils.hexToHsl(statusBadgeColor, 93) : '#D7F9E8',
        width: "90%",
        justifyContent: "normal",
        ".MuiChip-label": {
            marginLeft: "auto",
            marginRight: "auto",
        }
  }));

  const displayStatus = ((data.statusDisplayText || data.status) as string).replace("_", " ");

  const showAlternativeProcedureBox : boolean | undefined = useMemo(() => {
    if (currentJourney && currentJourney.journeyType !== 'I9_ALT'){
      return false;
    }

    if (["SECTION_2_OPT_OUT_REQUESTED", "SECTION_3_OPT_OUT_REQUESTED", "SECTION_3_REHIRE_OPT_OUT_REQUESTED",
      "SECTION_3_RENAME_OPT_OUT_REQUESTED"].includes(data.status as string)) {
      return false;
    }

    if (["REFERRED_SECTION_2", "REFERRED_SECTION_3", "REFERRED_SECTION_3_REHIRE", "REFERRED_SECTION_3_RENAME"].includes(data.status as string)) {
      return false;
    }

    if (data.status === 'ARCHIVED') {
      return false;
    }
    if (data.status === 'FAILED') {
      return false;
    }
    if (data.status === 'CANCELLED') {
      return false;
    }
    if (statusSummary.Section_3.status === 'in_progress') {
      return true;
    }
    if (statusSummary.Section_2.status === 'completed') {
      return false;
    }
    if (statusSummary.Section_1.status === 'completed') {
      return true;
    }
  }, [statusSummary, data.status]);

  const supBAltProcedureStatusTitle :  string | undefined = useMemo(() => {
    if(["SECTION_3_INVITED", "SECTION_3_SUBMITTED"].includes(data.status as string)){
      return `(Supplement B: Reverification)`;
    }
    if(["SECTION_3_REHIRE", "SECTION_3_REHIRE_SUBMITTED"].includes(data.status as string)){
      return `(Supplement B: Rehire)`;
    }
    if(["SECTION_3_RENAME", "SECTION_3_RENAME_SUBMITTED"].includes(data.status as string)){
      return `(Supplement B: Name Change)`;
    }
  }, [data.status]);

  const {data : oauthStatus} = useOAuthStatus();

  const AlternativeProcedureStatuses = {
    isDocumentsSubmitted: data.isDocumentSubmitted,
    isCallScheduled: data.isDocumentSubmitted && data.meeting,
    isAccountAuthenticated: oauthStatus?.authorised
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const caseData: any = data
  const isWorkAuthExpirable = caseData.additionalData && caseData.additionalData.Section_1 && caseData.additionalData.Section_1[0].employee?.work_auth?.expiration_date !== "";

  const isPhotoMatch = data.everify_case_status === "PHOTO_MATCH"
  const isScanAndUpload = data.eveify_case_status === "SCAN_AND_UPLOAD"
  const isFinalNonConfirmation = data["everify_case_eligibility_statement"] !== "EMPLOYMENT_AUTHORIZED" && ["CLOSED", "FINAL_NONCONFIRMATION"].includes(data.everify_case_status as string);
  const isShowSubmitToEverifyWarning  = data.everify_case_creation_failed === true
      && !isFinalNonConfirmation && !showAlternativeProcedureBox && data.status === 'SECTION_2_COMPLETE'
  const formatMeetingDate = (date: Date | string) =>
      date === ""
          ? date
          : formatDate(date, {
            weekday: "long",
            day: "2-digit",
            month: "long",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true
          });

  const formatDueDateDate = (
        date: Date | string
    ) => formatDate(date, {
        weekday: "long",
        day: "2-digit",
        month: "long",
        year: "numeric",
    })

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {showAlternativeProcedureBox &&
            <AlternativeProcedureBox className={statusSummary.Section_3.status === "in_progress" ? 'supb-box' : ''}>
              <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                Alternative procedure {statusSummary.Section_3.status === "in_progress" ? supBAltProcedureStatusTitle : '(Section 2)'} – remote examination of employee&apos;s documentation and completing Form I-9
              </Typography>
              <p>Your organization has elected to use DHS&apos; alternative procedure.</p>

              <p>What does this mean for you?</p>

              <p>Employees submit their identity and work authorization documentation to you electronically. This is supported by an app that simply and securely captures their documentation and presents it for you visually in the employee&apos;s I-9 case file.</p>

              <p>Upon receipt of the employee&apos;s documentation, an appointment for a live video call is automatically scheduled with the employee during the hours specified by your system administrator. The appointment time is identified below.</p>

              <p>The purpose of the video call is for one of the HR team to remotely examine the employee&apos;s documentation {statusSummary.Section_3.status === "in_progress" ? '(which should be from List A OR List C)' : '(which should be from List A OR a combination of documentation from List B and List C)'}, confirming that they appear genuine and relate to the named employee who is
              on the call.</p>

              <p>Calls are scheduled for 10 minutes with the scheduled start time displayed below. You can start the call by clicking on the &apos;join appointment/meeting&apos; tab.</p>

              <p>Please follow the instructions for the call by clicking on the &apos;instructions&apos; tab displayed on the next screen, just below the employee&apos;s documentation.</p>
              {AlternativeProcedureStatuses.isCallScheduled  && <p style={{fontWeight: "bold"}}> {`Meeting at : ${formatMeetingDate((data.meeting as Meeting).startTime)} , scheduled by : ${(data.meeting as Meeting).username}`}  </p>}
              {
                AlternativeProcedureStatuses.isCallScheduled ? <VerifyDocumentsButton
                    variant={"contained"}
                    onClick={()=>{setScheduleAppointmentModalOpen(!isScheduleAppointmentModalOpen)}}
                    sx={{marginRight: 2}}
                >
                  Reschedule Appointment
                </VerifyDocumentsButton> : null
              }
              {
                AlternativeProcedureStatuses.isCallScheduled ?
                <VerifyDocumentsButton
                variant="contained"
                disabled={!AlternativeProcedureStatuses.isDocumentsSubmitted }
                href={`/cases/${data.uid}/verify-documents`}>
                      Verify Documentation <ArrowForwardIosIcon />
                  </VerifyDocumentsButton> :
                  <VerifyDocumentsButton
                  variant="contained"
                  onClick={() => setScheduleAppointmentModalOpen(!isScheduleAppointmentModalOpen)}
                  disabled={!AlternativeProcedureStatuses.isDocumentsSubmitted }>
                      Schedule Appointment
                  </VerifyDocumentsButton>
              }
              {!AlternativeProcedureStatuses.isAccountAuthenticated &&
                <AuthenticatedAccountAlert variant="outlined" severity="info">
                  <AlertTitle>Authentication required to sync to calendar</AlertTitle>
                  In order to perform the verification process, you must connect and authenticate the connection with your calendar. Go to settings to perform authentication.
                </AuthenticatedAccountAlert>
              }

            </AlternativeProcedureBox>
          }
          { data.status === 'SECTION_2_OPT_OUT_REQUESTED' &&
              <RequestOptOutFromSection2Box>
                    <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                      Request to Opt Out of the Alternative Procedure
                    </Typography>
                    <p>The candidate has requested to opt out of the Alternative Procedure authorized by DHS for remote examination of their Form I-9 documentation.
                      To proceed, press <b>‘Opt out of Alternative Procedure’</b> below to transfer this case to the ‘Hiring Manager’ for completing the employee’s Form I-9.</p>

                    <p><b>Note:</b> This will move the case to the Hiring Manager Dashboard, accessible via the map.</p>

                    <p>Kindly confirm this request by the specified date below.</p>
                    <p><b>{formatDueDateDate(data.i9_section_2_deadline as string)}</b></p>
              </RequestOptOutFromSection2Box>
          }
          { ["SECTION_3_OPT_OUT_REQUESTED", "SECTION_3_REHIRE_OPT_OUT_REQUESTED", "SECTION_3_RENAME_OPT_OUT_REQUESTED"].includes(data.status as string) &&
              <RequestOptOutFromSection3Box>
                  <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                    Request to Opt Out of the Alternative Procedure
                  </Typography>
                  <p>The candidate has requested to opt out of the Alternative Procedure authorized by DHS for remote examination of their Form I-9 documentation.
                    To proceed, press <b>‘Opt out of Alternative Procedure’</b> below to transfer this case to the ‘Hiring Manager’ for completing the employee’s Form I-9.</p>

                  <p><b>Note:</b> This will move the case to the Hiring Manager Dashboard, accessible via the map.</p>

                  {data.i9_section_3_deadline as string &&
                    <>
                      <p>Kindly confirm this request by the specified date below.</p>
                      <p><b>{formatDueDateDate(data.i9_section_3_deadline as string)}</b></p>
                    </>
                  }
              </RequestOptOutFromSection3Box>
          }
          { (data.status === 'REFERRED_SECTION_3' || data.status === 'REFERRED_SECTION_2'
              || data.status === "REFERRED_SECTION_3_REHIRE" || data.status ===  "REFERRED_SECTION_3_RENAME") &&
              <ReferredBox>
                <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                  {`Alternative procedure (Section ${data.status === 'REFERRED_SECTION_2'? 2 : 3}) – remote examination of employee’s documentation and completing Form I-9 was referred.`}
                </Typography>
                <p>This case has been referred by your HR team, however, you still have the option to reschedule a live video call with the employee before the end date of this case.<br/></p>
                <b>{`${data.referredReason}`}</b>
                <p>Your organization has elected to use DHS alternative procedure.<br/><br/>
                  What does this mean for you?<br/><br/>
                  Employees submit their identity and work authorization documentation to you electronically. This is supported by an app that simply and securely captures their documentation and presents it for you visually in the employee’s I-9 case file.<br/><br/>
                  Upon receipt of the employee’s documentation, an appointment for a live video call is automatically scheduled with the employee during the hours specified by your system administrator. The appointment time is identified below.<br/><br/>
                  The purpose of the video call is for one of the HR team to remotely examine the employee’s documentation (which should be from List A OR a combination of documentation from List B and List C), confirming that they appear genuine and relate to the named employee who is on the call.<br/><br/>
                  Calls are scheduled for 10 minutes with the scheduled start time displayed below. You can start the call by clicking on the ’join appointment/meeting’ tab.<br/><br/>
                  Please follow the instructions for the call by clicking on the ’instructions’ tab displayed on the next screen, just below the employee’s documentation.</p>
                <VerifyDocumentsButton
                      variant={"contained"}
                      onClick={()=>{setRescheduleAppointmentModalOpen(!isRescheduleAppointmentModalOpen)}}
                      sx={{marginRight: 2}}
                  >
                    Reschedule Appointment
                  </VerifyDocumentsButton>
              </ReferredBox>
          }
          {
            isPhotoMatch && <PhotoMatchBox>
              <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>E-Verify Photo Matching</Typography>
                <p>E-Verify has required a photo matching review by the HR. The photo provided by E-Verify and the candidate’s documents are now available for your review.</p>
                <p>When you are ready, click the ‘Start E-Verify Photo Matching’ tab to proceed.</p>
                <VerifyDocumentsButton
                      variant={"contained"}
                      href={`/cases/${data.uid}/photo-match`}
                      sx={{marginRight: 2}}
                  >
                    Start E-Verify Photo Matching
                  </VerifyDocumentsButton>
            </PhotoMatchBox>
          }
          {
            isScanAndUpload && <PhotoMatchBox>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>Tentative Non-Confirmation – E-Verify Account holder Action Required</Typography>
              <p>An HR has declared to E-Verify that ‘the photo does not match’ or ‘no photo displayed during the Photo Match review’.</p>
              <p>This response has been sent to E-Verify.</p>
              <p>To proceed, please speak to your E-Verify Account holder to manage the case on E-Verify website.</p>
          </PhotoMatchBox>
          }
          {
            isFinalNonConfirmation && <FNCBox>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>Final Non-Confirmation – Resubmit to E-Verify</Typography>
              <p>This case has resulted in a Final Non-Confirmation by E-Verify.</p>
              <p>What does this mean for you?</p>
              <p>You may create a new case in E-Verify by pressing the button below to resubmit this case, as the previous one was closed in E-Verify.</p>
              <p>This ensures your organization follows E-Verify protocols for handling unresolved cases. Please consult your system administrator if you require further assistance.</p>
              <VerifyDocumentsButton
                      variant={"contained"}
                      sx={{marginRight: 2}}
                      disabled={isDisabledEverifyResubmit}
                      onClick={handleEverifyResubmit}
                  >
                    Re-submit this case to E-Verify
                  </VerifyDocumentsButton>
          </FNCBox>
          }
          {
              isShowSubmitToEverifyWarning && <FNCBox>
                <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>Case Creation Failed – Requires Submission to E-Verify</Typography>
                <p>A case could not be created in E-Verify due to an issue during the process.</p>
                <p>What does this mean for you?</p>
                <p>There was an issue with the information provided. Please review the details in Sections 1 and 2 of the Form I-9 to ensure the information and format are accurate, and confirm that the Social Security Number (SSN) is properly included. Make any necessary corrections, or contact your system administrator for assistance in resolving the issue.</p>
                <p>Once you have confirmed that the information and format are correct, you can create a new case in E-Verify by clicking the button below.</p>
                <VerifyDocumentsButton
                    variant={"contained"}
                    sx={{marginRight: 2}}
                    disabled={isDisabledEverifyResubmit}
                    onClick={handleEverifyResubmit}
                >
                  Submit this case to E-Verify
                </VerifyDocumentsButton>
              </FNCBox>
          }
        </Grid>
        <Grid item xs={5}>
          <CaseProfileBox>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {
                  ['ARCHIVED', 'FAILED', 'CANCELLED'].includes(data.status as string) ?
                  <StatusBadge icon={<></>} label={displayStatus} /> :
                  expiryMessage ? <ExpirationDateBadge
                      icon={<NotificationsNoneIcon />}
                      label={expiryMessage}
                      color="error"
                  /> : <div style={{minHeight: "40px"}}>{" "}</div>
                }

              </Grid>
              <Grid item xs={12}>
                <AccountCircleOutlinedIcon
                  style={{ margin: "20px 0px 10px 0px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{ fontSize: "24px", fontWeight: "bold" }}
                >{`${data.givenName} ${data.familyName}`}</Typography>
                <Typography
                  sx={{ fontSize: "20px", color: "#666666" }}
                >{`${data.consumerReference}`}</Typography>
                {(["SECTION_2_OPT_OUT_REQUESTED", "SECTION_3_OPT_OUT_REQUESTED", "SECTION_3_REHIRE_OPT_OUT_REQUESTED", "SECTION_3_RENAME_OPT_OUT_REQUESTED"].includes(data.status as string))
                    && <VerifyDocumentsButton
                        style={{width: "90%"}}
                        variant="contained"
                        disabled={optingOutInProcess}
                        onClick={() => optOut()}>
                      Opt out of Alternative Procedure
                    </VerifyDocumentsButton>
                }
              </Grid>
              <Grid item xs={12}>
                {(data.everify_case_number || data.everify_case_creation_failed == true) && (
                  <EverifyDetailsBox>
                    <p>
                      <strong>E-Verify Details</strong>
                    </p>
                    {data.everify_case_number && (
                        <p>
                            Case Number: <span>{ `${data.everify_case_number}` }</span>
                        </p>
                    )}
                    <p>
                      Case Status: <span>{
                          data.everify_case_creation_failed == true ? 'Case Creation Failed'
                              : (data.everify_case_eligibility_statement ? `${data.everify_case_eligibility_statement}`
                                  : `${data.everify_case_status_display}`) }</span>
                    </p>
                    <Button
                      variant="contained"
                      sx={{
                        color: "#fff",
                        background: "#000",
                        maxHeight: 30,
                        margin: "10px 0px 10px 0px",
                        textTransform: "none",
                        width: "100%",
                        borderRadius: "8px"
                      }}
                      onClick={handleEverifyUpdate}
                    >
                      Refresh E-Verify Status
                    </Button>
                  </EverifyDetailsBox>
                )}
              </Grid>
            </Grid>
          </CaseProfileBox>
        </Grid>
        <Grid item xs={7}>
          <CaseDetailsBox>
            <DetailsBlock xs={12} title={"Case Details"} borderBottom>
              <Grid item xs={12}>
                <p>
                  Created: <span>{formatDateTime(`${data.createdAt}`)}</span>
                </p>
              </Grid>
            </DetailsBlock>
            {currentJourney?.flowType &&  ['I9_ALT', 'I9_HIRING_MANAGER'].includes(currentJourney.flowType) &&
            <DetailsBlock xs={12} title={"Hiring Manager Details"} borderBottom editButton editButtonClick={() => setDisabled(!isDisabled)}>
              <Grid container alignItems={"center"} item xs={12}>
                <Grid item xs={6}>
                  <p>First Name:</p>
                </Grid>
                <Grid item xs={6} sx={{ marginTop: 2 }}>
                  <TextField
                    onChange={(e) => setHiringManagerName(e.target.value)}
                    fullWidth
                    size={"small"}
                    value={hiringManagerName}
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems={"center"} item xs={12}>
                <Grid item xs={6}>
                  <p>Last Name:</p>
                </Grid>
                <Grid item xs={6} sx={{ marginTop: 2 }}>
                  <TextField
                    onChange={(e) => setHiringManagerLastName(e.target.value)}
                    fullWidth
                    size={"small"}
                    value={hiringManagerLastName}
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems={"center"} item xs={12}>
                <Grid item xs={6}>
                  <p>Email:</p>
                </Grid>
                <Grid item xs={6} sx={{ marginTop: 2 }}>
                  <TextField
                    onChange={(e) => setHiringManagerEmail(e.target.value)}
                    fullWidth
                    size={"small"}
                    value={hiringManagerEmail}
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems={"center"} item xs={12}>
                <Grid item xs={6}>
                  <p>Phone:</p>
                </Grid>
                <Grid item xs={6} sx={{ marginTop: 2 }}>
                  <TextField
                    onChange={(e) => setHiringManagerPhone(e.target.value)}
                    fullWidth
                    size={"small"}
                    value={hiringManagerPhone}
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems={"center"} item xs={12}>
                <Grid item xs={6}>
                  <p>Title:</p>
                </Grid>
                <Grid item xs={6} sx={{ marginTop: 2 }}>
                  <TextField
                    onChange={(e) => setHiringManagerTitle(e.target.value)}
                    fullWidth
                    size={"small"}
                    value={hiringManagerTitle}
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
            </DetailsBlock>}
            <DetailsBlock xs={12} title={"Employment Details"} borderBottom>
              <Grid container alignItems={"center"}>
                <Grid item xs={5}>
                  Start Date:
                </Grid>
                <Grid item xs={7}>
                  <StartEndDateField
                    value={startDate}
                    onAccept={handleStartDateChange}
                    disabled={!!auditRole}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems={"center"}>
                <Grid item xs={5}>
                  End Date:
                </Grid>
                <Grid item xs={7}>
                  <StartEndDateField
                    value={endDate}
                    slotProps={{
                      actionBar: {
                        actions: ["clear"],
                        sx: {
                          background: "#00255440",
                        },
                      },
                    }}
                    onAccept={handleEndDateChange}
                    disabled={!!auditRole}
                  />
                </Grid>
              </Grid>
            </DetailsBlock>
            <DetailsBlock xs={12} title={"Contact Details"} borderBottom>
              <Grid item xs={12}>
                <p>
                  Email: <span>{`${data.emailAddress}`}</span>
                </p>
              </Grid>
              <Grid item xs={12}>
                <p>
                  Cell / Mobile Number: <span>{`${data.mobileNumber}`}</span>
                </p>
              </Grid>
            </DetailsBlock>
            <DetailsBlock xs={12} title={"Location Details"}>
              <Grid item xs={12}>
                <p>
                  Country: <span>{`${data.country}`}</span>
                </p>
              </Grid>
              <Grid item xs={12}>
                <p>
                  Location: <span>{`${getLocationForId(data.locationId as number)?.name || ""}`}</span>
                </p>
              </Grid>
            </DetailsBlock>
          </CaseDetailsBox>
        </Grid>
        <Grid item xs={12}>
          <SummaryBox>
            <DetailsBlock xs={12} title={"Status Summary"}>
              <TableContainer component={Paper} sx={{ flexGrow: 1 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StatusSummaryTableCell>Section</StatusSummaryTableCell>
                      <StatusSummaryTableCell>Invited</StatusSummaryTableCell>
                      <StatusSummaryTableCell>Status</StatusSummaryTableCell>
                      <StatusSummaryTableCell>Completed</StatusSummaryTableCell>
                      <StatusSummaryTableCell>
                        Expires/Due
                      </StatusSummaryTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[
                      {
                        statusName: "Section 1",
                        statusDetails: statusSummary.Section_1,
                      },
                      {
                        statusName: "Section 2",
                        statusDetails: statusSummary.Section_2,
                      },
                      {
                        statusName: "Supplement B",
                        statusDetails: statusSummary.Section_3,
                      },
                    ].map(({ statusName, statusDetails }) => (
                      <StatusSummaryTableRow key={`${statusName}-details`}>
                        <StatusSummaryTableCell>
                          {statusName}
                        </StatusSummaryTableCell>
                        <StatusSummaryTableCell>
                          {formatDateTime(statusDetails.invited)}
                        </StatusSummaryTableCell>
                        <StatusSummaryTableCell>
                          {statusDetails.status !== "" && (statusName !== "Section 2" || statusDetails.invited.length > 0 || statusDetails.status === 'completed') && (
                            <CaseListStatusBadge
                              status={
                                statusName === "Section 1" ? (statusDetails.status === "completed"? "SECTION_1_COMPLETE" : "SECTION_1_INVITED") :
                                statusName === "Section 2" ? statusDetails.status.includes("OPT_OUT_REQUESTED") ? statusDetails.status : (statusDetails.status === "completed"? "SECTION_2_COMPLETE" : "SECTION_2_INVITED") :
                                    (statusDetails.status.includes("OPT_OUT_REQUESTED")? statusDetails.status : (statusDetails.status === "completed"? "SECTION_3_COMPLETE" : "SECTION_3_INVITED"))
                              }
                              isWorkAuthExpirable={isWorkAuthExpirable}
                            />
                          )}
                        </StatusSummaryTableCell>
                        <StatusSummaryTableCell>
                          {formatDateTime(statusDetails.completed)}
                        </StatusSummaryTableCell>
                        <StatusSummaryTableCell>
                          {formatDateTime(statusDetails.due)}
                        </StatusSummaryTableCell>
                      </StatusSummaryTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DetailsBlock>
          </SummaryBox>
        </Grid>
        <ConfirmationModelContainer {...modal} />
        <ScheduleAppointmentModal isOpen={isScheduleAppointmentModalOpen}
                                  onClose={() => setScheduleAppointmentModalOpen(!isScheduleAppointmentModalOpen)}
                                  caseId={data.uid as string}
                                  startDate={startDate as string}
                                  section2Deadline={data.i9_section_2_deadline as string}
                                  isAuthenticated={!!AlternativeProcedureStatuses.isAccountAuthenticated}
                                  isReschedule={!!AlternativeProcedureStatuses.isCallScheduled}
                                  candidatename={data.givenName + ' ' + data.familyName}
                                  />
        <ScheduleAppointmentModal isOpen={isRescheduleAppointmentModalOpen}
                                  onClose={() => setRescheduleAppointmentModalOpen(!isRescheduleAppointmentModalOpen)}
                                  caseId={data.uid as string}
                                  startDate={startDate as string}
                                  section2Deadline={data.i9_section_2_deadline as string}
                                  isAuthenticated={!!AlternativeProcedureStatuses.isAccountAuthenticated}
                                  isReschedule={true}
                                  candidatename={data.givenName + ' ' + data.familyName}
        />
      </Grid>
      {!isDisabled && <AppBar
          position="fixed"
          sx={{
            borderTop: 1,
            borderColor: "divider",
            top: "auto",
            bottom: 0,
            background: "white",
          }}
        >
          <Grid container item xs={12} style={{ flexGrow: 1, padding: 20 }}>
            <Grid item xs={8}></Grid>
            <Grid
              container
              item
              xs={4}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <SecondaryButton variant="contained" onClick={() => setDisabled(!isDisabled)}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                onClick={handleHiringManagerChange}
                disabled={isDisabled}
              >
                Submit
              </PrimaryButton>
            </Grid>
          </Grid>
        </AppBar>}
    </>
  );
};
