import {
  AppBar,
  Button,
  FormControlLabel,
  Grid,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import HeaderBar from "../../components/HeaderBar";
import { useParams } from "react-router-dom";
import { useCaseDetails } from "../../hooks/Cases/useCaseDetails";
import { TabPanel } from "../../components/CaseDetailsForm/ComparePanel";
import DocumentImage from "../../components/DocumentImage";
import {
  getDisplayDocumentTitle,
  getDisplayDocumentTitleAboveImage,
} from "../../utils/CaseUtils/documentsDisplayUtil";
import { useEffect, useState } from "react";
import { CompareField } from "../../components/CaseDetailsForm/ComparePanel/styles";
import { useEverifyPhotoMatch, useEverifyRetrievePhoto } from "../../hooks/Cases/useMutateCase";
import { PhotoMatch as PhotoMatchProps } from "../../models/requests/UpdateEverifyRequest";
import {
  PrimaryButton,
  SecondaryButton,
} from "../CreateCase/CreateCaseForm/styles";

const PhotoMatchOptions = [
  {
    label: "Yes, this photo matches",
    value: "matching",
  },
  {
    label: "No, this photo does not match",
    value: "not-matching",
  },
  {
    label: "No photo displayed",
    value: "no-image",
  },
];

const PhotoMatch = () => {
  const [selectedDocument, setSelectedDocument] = useState<number>(0);
  const { caseId }: { caseId: string } = useParams();
  const { data, isLoading } = useCaseDetails(caseId);

  const [photoMatchOption, setPhotoMatchOption] = useState<PhotoMatchProps>(
    "matching" as PhotoMatchProps
  );
  const { mutate } = useEverifyPhotoMatch(caseId);
  const { mutate: loadImage} = useEverifyRetrievePhoto(caseId);

  if (isLoading || !data) {
    return <LinearProgress />;
  }

  useEffect(() => {
    loadImage()
  }, [])

  // eslint-disable-next-line
  const standaloneTransactionsSectionTwo: any =
    // eslint-disable-next-line
    (data.additionalData.standaloneTransactions as any).Section_2_Documents ||
    [];
  // eslint-disable-next-line
  const sectionTwoDocumentImages: any = [];
  standaloneTransactionsSectionTwo &&
    // eslint-disable-next-line
    standaloneTransactionsSectionTwo.forEach((transaction: any) => {
      Object.keys(transaction.documents)
        .sort()
        .forEach((key) =>
          sectionTwoDocumentImages.push({
            title: key,
            image: transaction.documents[key].image,
          })
        );
    });

  
  const getMimeTypeFromBase64 = (base64: string) => {
      if (base64.startsWith('/9j/')) return 'image/jpeg'; // JPEG
      if (base64.startsWith('iVBOR')) return 'image/png'; // PNG
      if (base64.startsWith('R0lGOD')) return 'image/gif'; // GIF
      if (base64.startsWith('UklGR')) return 'image/webp'; // WebP
      return null; // Unknown
  };

  const photoMatchImage =
    data.additionalData.everify_case_document_photo || '';
  const mimeType = getMimeTypeFromBase64(photoMatchImage as string);

  const everifyPhotoMatchImage = `data:${mimeType};base64,${photoMatchImage}`;;
  
  const handleEverifyPhotoMatch = () => {
    mutate(photoMatchOption);
  };

  return (
    <HeaderBar
      breadcrumbs={[
        {
          label: "I-9 Cases",
          path: "/cases",
        },
        ...(data ? [{ label: `${data.givenName} ${data.familyName}` }] : []),
        {
          label: caseId,
        },
        {
          label: "Photo Match",
        },
      ]}
    >
      <Grid container sx={{ paddingBottom: "100px" }}>
        <Grid
          alignItems={"center"}
          item
          xs={12}
          sx={{ borderBottom: 1, borderColor: "divider", padding: 2 }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Photo Match
          </Typography>
          <p>
            Does the photo displayed match the photo displayed on{" "}
            {data.familyName} {data.givenName}?
          </p>
          <RadioGroup
            style={{ marginBottom: "25px" }}
            aria-labelledby="photo-match-options"
            name="photo-match-options"
            onChange={(e) =>
              setPhotoMatchOption(e.target.value as PhotoMatchProps)
            }
          >
            {PhotoMatchOptions.map((option, idx) => {
              return (
                <FormControlLabel
                  key={"photo-match-option-" + idx}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              );
            })}
          </RadioGroup>
        </Grid>
        <Grid
          alignItems={"top"}
          container
          xs={12}
          gap={8}
          sx={{ borderBottom: 1, borderColor: "divider", padding: 2 }}
        >
          <Grid item xs={4}>
            <Typography sx={{ fontSize: "16px", marginBottom: "10px" }}>
              {data.familyName} {data.givenName}
            </Typography>
            {everifyPhotoMatchImage ? (
              <DocumentImage
                imageName={`${data.familyName} ${data.givenName}`}
                imageScr={everifyPhotoMatchImage}
                showImageName={false}
              />
            ) : (
              <div
                style={{
                  padding: "120px 80px",
                  borderRadius: 4,
                  position: "relative",
                  background: "#F4F4F4",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    marginBottom: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Awaiting Image...
                </Typography>
                <p style={{ fontSize: "14px" }}>
                  Please press the refresh button to retrieve and display the
                  image from E-Verify.
                </p>
                <Button
                  variant="contained"
                  sx={{
                    color: "#fff",
                    background: "#000",
                    maxHeight: 30,
                    margin: "10px 0px 10px 0px",
                    textTransform: "none",
                    width: "100%",
                    borderRadius: "8px",
                  }}
                  href={
                    window.location.origin +
                    "/cases/" +
                    data.uid +
                    "/photo-match"
                  }
                >
                  Refresh
                </Button>
              </div>
            )}
          </Grid>
          <Grid item xs={5}>
            <Typography sx={{ fontSize: "16px", marginBottom: "10px" }}>
              Document Image
            </Typography>
            <CompareField
              variant="filled"
              select
              // eslint-disable-next-line
              onChange={(event: any) => {
                setSelectedDocument(event.target.value);
              }}
            >
              {sectionTwoDocumentImages.length > 0 &&
                sectionTwoDocumentImages.map(
                  (item: { title: string; image: string }, index: number) => (
                    <MenuItem key={item.title} value={index}>
                      {getDisplayDocumentTitle(item.title)}
                    </MenuItem>
                  )
                )}
            </CompareField>
            {sectionTwoDocumentImages.map(
              (item: { title: string; image: string }, index: number) => (
                <TabPanel
                  key={item.image}
                  value={selectedDocument}
                  index={index}
                >
                  <DocumentImage
                    key={item.image}
                    imageName={getDisplayDocumentTitleAboveImage(
                      sectionTwoDocumentImages,
                      item.title
                    )}
                    imageScr={item.image}
                    showImageName={false}
                  />
                </TabPanel>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
      <AppBar
        position="fixed"
        sx={{
          borderTop: 1,
          borderColor: "divider",
          top: "auto",
          bottom: 0,
          background: "white",
        }}
      >
        <Grid container item xs={12} style={{ flexGrow: 1, padding: 20 }}>
          <Grid item xs={8}></Grid>
          <Grid
            container
            item
            xs={4}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <SecondaryButton variant="contained" href={`/cases/${caseId}`}>
              Exit, no decision
            </SecondaryButton>
            <PrimaryButton onClick={handleEverifyPhotoMatch}>
              Submit
            </PrimaryButton>
          </Grid>
        </Grid>
      </AppBar>
    </HeaderBar>
  );
};

export default PhotoMatch;
