import {useQuery} from "@tanstack/react-query";
import axios, {AxiosError} from "axios";
import {CaseResponse, CaseStatusDictionary, getStatus} from "../../models/responses/Cases/CaseResponse";
import {useAxiosParams} from "../common/useAxiosParams";
import {formatDate} from "../../utils/DateUtils/dateUtils";
import {useContext} from "react";
import JourneyContext from "../../context/JourneyContext";

type EverifyCaseCount = {totalCount?: number, finalNonConfirmation?: number, tentativeNonConfirmation?: number,
    employmentAuthorized?: number,  caseCreationFailed?: number}

interface auditCriteriaProps {
  dateFrom?: string;
  dateTo?: string;
  locationIds: string[];
}

export const useCaseList = (brandId: string) => {
    const {basePath, config} = useAxiosParams();
    const allCases = useQuery<unknown, AxiosError, CaseResponse[]>({
        queryKey: ["cases", brandId],
        queryFn: async () => {
            const response = await axios.get<CaseResponse[]>(basePath + `/list?brandId=${brandId}`, config);
            return response.data;
        },
        refetchInterval: 2 * 60 * 1000,
    })
    const {currentJourney} = useContext(JourneyContext);
    const auditCriteriaCases = ({ dateFrom, dateTo, locationIds }: auditCriteriaProps) => {
      const filteredArray = allCases.data && allCases.data.filter((item) => {
          const startDate = dateFrom ? new Date(formatDate(dateFrom || '')) : null;
          const endDate = dateTo ? new Date(formatDate(dateTo || '')) : null;
          const caseStartdate = new Date(formatDate(item.startDate || ''));
          const dateRange = (!startDate || caseStartdate >= startDate) && (!endDate || caseStartdate <= endDate)
          const caseLocation = locationIds.length === 0 || locationIds.includes(String(item.locationId));
          const isCaseActive = !['ARCHIVED', 'FAILED'].includes(item.status);
          const isCurrentJourney = item.journeyId === currentJourney?.uid;
          return dateRange && caseLocation && isCaseActive && isCurrentJourney;
        });

        if(!filteredArray) return [];

        return filteredArray;
    }

    const summaryCases = allCases.data?.filter((item) => item.journeyId === currentJourney?.uid);

    const everifyCases = summaryCases?.filter(obj => obj.additionalData["everify_case_status"] || obj.additionalData["everify_case_creation_failed"]);

    const everifyCaseCount : ()=>EverifyCaseCount = () => {
        if (!everifyCases) {
            return {};
        }
        return {
            totalCount: everifyCases.length,
            finalNonConfirmation: everifyCases.filter(obj => obj.additionalData["everify_case_eligibility_statement"] !== "EMPLOYMENT_AUTHORIZED" && ["CLOSED", "FINAL_NONCONFIRMATION"].includes(obj.additionalData.everify_case_status || "") && obj.additionalData["everify_case_creation_failed"] !== true).length,
            tentativeNonConfirmation: everifyCases.filter(obj => obj.additionalData["everify_case_eligibility_statement"] !== "EMPLOYMENT_AUTHORIZED" && !["CLOSED", "FINAL_NONCONFIRMATION"].includes(obj.additionalData.everify_case_status || "") && obj.additionalData["everify_case_creation_failed"] !== true).length,
            employmentAuthorized: everifyCases.filter(obj => obj.additionalData["everify_case_eligibility_statement"] === "EMPLOYMENT_AUTHORIZED").length,
            caseCreationFailed: everifyCases.filter(obj => obj.additionalData["everify_case_creation_failed"] == true).length,
        }
    }

    const rtwCaseCount = (days: number, filter: string, timestamp: string, type: string) => {
      const daysAgoFromNow = new Date();
      daysAgoFromNow.setDate(daysAgoFromNow.getDate() - days);
      const daysFromNow = new Date();
      daysFromNow.setDate(daysFromNow.getDate() + days)

      const statusList = currentJourney?.uiConfig.filter[filter]?.statuses || [];

      if(type === "IN_THE_LAST"){
        return summaryCases?.filter(obj => statusList.includes(obj.status) && new Date(obj.additionalData?.[timestamp] as string) > daysAgoFromNow).length as number;
      }
      else{
        return summaryCases?.filter(obj => statusList.includes(obj.status) && new Date(obj.additionalData?.[timestamp] as string) < daysFromNow).length as number;
      }      
      
    }

    const dueDateSection = (days: number, filter: string, exactStatus?: string, timestamp?: string) => {
      const today = new Date();
      const daysFromNow = new Date();
      daysFromNow.setDate(today.getDate() + days);
      let dueDateCount = 0;
      
      let deadlineDateStr: string | undefined = '' ;

      const statusList = currentJourney?.uiConfig.filter[filter]?.statuses || [];

      summaryCases?.filter(obj => statusList.includes(obj.status) && (!exactStatus || exactStatus === obj.status)).forEach((obj) => {
        if(filter === 'Section_1_In_Progress'){
          deadlineDateStr = obj.additionalData.i9_section_1_deadline as string;
          const deadlineAsDate = new Date(deadlineDateStr as string);
          if (deadlineAsDate >= today && deadlineAsDate <= daysFromNow) {
            dueDateCount++;
          }
        } 
        if(filter === 'Section_2_In_Progress'){
          deadlineDateStr = obj.additionalData.i9_section_2_deadline;
          const deadlineAsDate = new Date(deadlineDateStr as string);
          if (deadlineAsDate >= today && deadlineAsDate <= daysFromNow) {
            dueDateCount++;
          }
        }
        if(filter === 'Section_3_In_Progress'){
          deadlineDateStr = obj.additionalData.i9_section_3_deadline;
          const deadlineAsDate = new Date(deadlineDateStr as string);
          if (deadlineAsDate >= today && deadlineAsDate <= daysFromNow) {
            dueDateCount++;
          }
        } 
        else{
          deadlineDateStr = obj.additionalData?.[timestamp as string] as string;
          const deadlineAsDate = new Date(deadlineDateStr as string);
          if (deadlineAsDate >= today && deadlineAsDate <= daysFromNow) {
            dueDateCount++;
          }
        }
      });
      return dueDateCount;
    };

    const correctionPending = () => {
      return summaryCases?.filter(obj => obj.status === "SECTION_1_CORRECTION").length;
    }

    const casesSummary =
      summaryCases && [
            {
                status: "Section_1",
                label: "Section 1",
                count: summaryCases.filter(c => CaseStatusDictionary["Section_1"]?.includes(getStatus(c))).length,
                filters: ["Section_1_In_Progress", "Section_1_Complete"],
            },
            {
                status: "Section_2",
                label: "Section 2",
                count: summaryCases.filter(c => CaseStatusDictionary["Section_2"]?.includes(getStatus(c))).length,
                filters: ["Section_2_In_Progress", "Section_2_Complete"],
            },
            {
                status: "Section_3",
                label: "Supplement B",
                count: summaryCases.filter(c => CaseStatusDictionary["Section_3"]?.includes(getStatus(c))).length,
                filters: ["Section_3_In_Progress", "Section_3_Complete"],
            },
            {
                status: "E_Verify",
                label: "E-Verify",
                count: everifyCases? everifyCases.length : 0,
                filters: ["E_Verify"],
            },
            {
                status: "Failed",
                label: "Failed",
                count: summaryCases.filter(c => CaseStatusDictionary["Archived"]?.includes(getStatus(c))).length,
                filters: ["Archived", "Failed"],
            },
            {
                status: "Active",
                label: "Active I-9 Cases",
                count: summaryCases.filter(c => !CaseStatusDictionary["Archived"]?.includes(getStatus(c))).length,
                filters: ["Section_1_In_Progress", "Section_1_Complete", "Section_2_In_Progress", "Section_2_Complete", "Section_3_In_Progress", "Section_3_Complete", "E_Verify"],
            }
        ]

    
    return {
      allCases: allCases.data,
      isSuccess: allCases.isSuccess,
      casesSummary,
      dueDateSection,
      rtwCaseCount,
      everifyCaseCount,
      correctionPending,
      auditCriteriaCases,
      summaryCases
    };
}
